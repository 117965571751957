<template>
	<div id="app">
		<navList></navList>
		<div>
			<router-view :key="$route.path" v-wechat-title="$route.meta.title" />
		</div>
		<bottomList></bottomList>
		<img id='upbutton' v-if="showbackTo" src="./assets/img/backTo.png" @click="backTop">
	</div>
</template>
<script>
	import navList from './views/nav.vue'
	import bottomList from './views/bottom.vue'
	export default {
		components: {
			navList,
			bottomList
		},
		data() {
			return {
				showbackTo: true,
			};
		},
		methods: {
			backTop() {
				localStorage.setItem('speciality_id',0)
				localStorage.setItem('course_id',0)
				this.$router.back(-1)
			},
		}
	}
</script>
<style scoped>
	#upbutton {
		position: fixed;
		right: 20px;
		bottom: 80px;
		width: 50px;
	}
</style>