import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import VueWechatTitle from 'vue-wechat-title';
Vue.use(VueWechatTitle)


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
Vue.config.productionTip = false

import initShowToast from "@/components/toast/ShowToast.js"
initShowToast(Vue)

import VueRouter from "vue-router";
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return routerPush.call(this, location, onResolve, onReject);
	return routerPush.call(this, location).catch((error) => error);
};

new Vue({
    el: '#app',
	router,
	store,
	render: h => h(App)
}).$mount('#app')