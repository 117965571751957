<template>
    <div id="navList">
        <div class="container-fluid d-none d-sm-block">
            <div class="row nava_bc">
                <div class="container">
                    <div class="row">
                        <div class="col-3 nav_logo" @click="bindNav()">
                            <img :src="configs.logo">
                        </div>
                        <div class="col-6 nav_content">
                            <div class="search">
                                <el-input v-model="keywords" :placeholder="typeList[type].label" clearable
                                    @clear="clear" @keyup.enter.native="bindKeywords">
                                    <i slot="prefix" class="el-input__icon el-icon-search" @click="bindKeywords"></i>
                                    <template #append>
                                        <el-select v-model="type" @change="change">
                                            <el-option v-for="(item, index) in typeList" :key="item.value"
                                                :label="item.label" :value="item.value"></el-option>
                                        </el-select>
                                    </template>
                                </el-input>

                                <!-- <el-input placeholder="请输入内容" clearable v-model="keywords"
                                    @keyup.enter.native="bindKeywords">
                                    <i slot="prefix" class="el-input__icon el-icon-search" @click="bindKeywords"></i>

                                </el-input> -->
                            </div>
                            <div class="nav_list">
                                <li class="nav_active" @click="bindNav">网站首页</li>
                                <li v-if="isNav == '1' && $store.state.userInfo.token" class="nav_son">
                                    专业成果
                                    <span>
                                <li v-for="(item, index) in specialityRes" :key="index"
                                    @click="bindSpecialityRe(item.speciality_id)">{{ item.speciality_title }}</li>
                                </span>
                                </li>
                                <li v-if="isNav == '2' && $store.state.userInfo.token" class="nav_son">
                                    年级
                                    <span>
                                <li v-for="(item, index) in gradeRes" :key="index"
                                    @click="bindSpecialitRe(item.grade_id)">{{item.grade_title }}</li>
                                </span>
                                </li>

                                <li @click="userInfo" v-if="$store.state.userInfo.token">个人中心</li>
                            </div>
                        </div>
                        <div class="col-3 nav_user_bc" v-if="$store.state.userInfo.token" @click="userInfo">
                            <div class="nva_user" v-if="$store.state.userInfo.avatar">
                                <img :src="$store.state.userInfo.avatar">
                            </div>
                            <div class="nav_user_li">
                                <li v-if="$store.state.userInfo.user_type == 1">
                                    登录用户：{{ $store.state.userInfo.username }}</li>
                                <li v-if="$store.state.userInfo.user_type == 2">学生：{{ $store.state.userInfo.true_name }}
                                </li>
                                <li>联系方式：{{ $store.state.userInfo.mobile }}</li>
                            </div>
                            <div class="iconfont icon-xiala1" @click="userInfo"></div>
                        </div>
                        <div class="col-3 nav_user_bc" v-else>
                            <div class="nva_user_login" @click="bindLogin">
                                登录/注册
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 手机版头部 -->
        <div class="container-fluid d-block d-sm-none sj_nava_bc">
            <div class="row">
                <div class="col-3 sj_logo" @click="bindNav()">
                    <img :src="configs.logo" class="img-fluid">
                </div>

                <div class="col-6 sj_nav">

                    <li class="nav_active" @click="bindNav">网站首页</li>
                    <li v-if="isNav == '1' && $store.state.userInfo.token" class="nav_son">
                        专业成果
                        <span>
                    <li v-for="(item, index) in specialityRes" :key="index"
                        @click="bindSpecialityRe(item.speciality_id)">{{
            item.speciality_title }}</li>
                    </span>
                    </li>
                    <li v-if="isNav == '2' && $store.state.userInfo.token" class="nav_son">
                        年级
                        <span>
                    <li v-for="(item, index) in gradeRes" :key="index" @click="bindSpecialitRe(item.grade_id)">{{
            item.grade_title }}</li>
                    </span>
                    </li>
                    <li @click="userInfo" v-if="$store.state.userInfo.token">个人中心</li>
                </div>

                <div class="col-3 sj_nva_user_list" v-if="$store.state.userInfo.token">
                    <div class="sj_nva_user">
                        <img :src="$store.state.userInfo.avatar">
                    </div>
                    <div class="nav_user_li">
                        <li>登录用户：{{ $store.state.userInfo.username }}</li>
                        <li>联系方式：{{ $store.state.userInfo.mobile }}</li>
                    </div>
                </div>
                <div class="col-3 nav_user_bc" v-else>
                    <div class="nva_user_login" @click="bindLogin">
                        登录/注册
                    </div>
                </div>

            </div>
            <div class="col-12 sj_nav">
                <div class="search">
                    <el-input placeholder="请输入内容" v-model="keywords" @keyup.enter.native="bindKeywords">
                        <i slot="prefix" class="el-input__icon el-icon-search" @click="bindKeywords"></i>
                    </el-input>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MiddleUtil from '../utils/MiddleUtil';
    import {
        post
    } from "@/http/api";

    export default {
        name: 'navList',
        data() {
            return {
                typeList: [{
                        label: '搜课程',
                        value: 0,
                    },
                    {
                        label: '搜附件',
                        value: 1,
                    },
                ],
                type: 0,
                keywords: '',
                configs: '',
                isNav: 1,
                specialityRes: [],
                gradeRes: [],
            };
        },
        watch: {
            '$route'(to) {
                if (to.name == 'grade') {
                    this.isNav = 2;
                } else {
                    this.isNav = 1;
                }
            }
        },
        created() {
            this.getBanner();
            this.getNavList()
        },
        methods: {
            change() {
                this.$emit("update:input", this.keywords);
                this.$emit("update:select", this.typeList);
                this.$emit("change", this.keywords, this.typeList);
                // this.bindKeywords();
            },
            clear() {
                this.$emit("update:input", "");
                this.$emit("update:select", "");
                this.$emit("change", "", "");
                // this.bindKeywords();
            },
            getNavList() {
                post("Index/getNavList").then((res) => {
                    this.specialityRes = res.data.specialityList;
                    this.gradeRes = res.data.gradelist;
                });
            },

            getBanner() {
                post("Index/getBanner").then((res) => {
                    this.configs = res.data.configs;
                });
            },

            bindNav() {
                this.$router.push({
                    path: `/`
                });
            },

            userInfo() {
                this.$router.push({
                    path: `/userInfo`
                });
            },

            bindLogin() {
                this.$router.push({
                    path: `/login`
                });
            },

            bindSpecialityRe(id) {
                this.$router.push({
                    path: `/courseList`,
                    query: {
                        id: id
                    }
                });
            },

            bindSpecialitRe(id) {
                this.$router.push({
                    path: `/grade`,
                    query: {
                        id: id
                    }
                });
            },

            bindKeywords() {
                var keywords = this.keywords;
                // if (!keywords) {
                //     this.$message({
                //         type: "info",
                //         message: "请输入关键词！",
                //     });
                //     return false;
                // }
                let speciality_id = localStorage.getItem('speciality_id');
                let course_id = localStorage.getItem('course_id');
                console.log('query',this.$route.query);
                console.log('id',this.$route.query.id);
                console.log('course_id',this.$route.query.course_id);
                console.log('localStoragecourse_id',course_id);
                if (this.$route.query.course_id || this.$route.query.id || this.$route.query.id) {
                    this.$router.push({
                        path: `/speciality`,
                        query: {
                            id: this.$route.query.id,
                            course_id: this.$route.query.course_id,
                            time: Date.now,
                            keywords: keywords
                        }
                    });
                    let data = {
                        keywords
                    }
                    MiddleUtil.$emit('spinit', data);
                } else {
                    if (!keywords) {
                        this.$message({
                            type: "info",
                            message: "请输入关键词！",
                        });
                        return false;
                    }
                    localStorage.setItem('speciality_id', 0)
                    localStorage.setItem('course_id', 0)
                    this.$router.push({
                        path: `/searchList`,
                        query: {
                            keywords: keywords,
                            time: Date.now,
                        }
                    });
                }
            
            },

        }
    }
</script>
<style src="../assets/css/head.css"></style>
<style src="../assets/css/iconfont.css"></style>
<style>
    .nva_user img {
        border-radius: 50%;
    }
</style>