import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
	userInfo: JSON.parse(localStorage.getItem('userInfo') || "{}"),
	id: JSON.parse(localStorage.getItem('id') || 0),
	token: JSON.parse(localStorage.getItem('token') || null),
	user_type: JSON.parse(localStorage.getItem('user_type') || 0),
	grade_id: JSON.parse(localStorage.getItem('grade_id') || -1),
	speciality_id: JSON.parse(localStorage.getItem('speciality_id') || -1),
}

const mutations = {
	updateUserInfo(state, payload) {
		state.userInfo = payload;
		state.id = payload.id || 0;
		state.token = payload.token || "";
		state.user_type = payload.user_type || -1;
		state.grade_id = payload.grade_id || -1;
		state.speciality_id = payload.speciality_id || -1;

		localStorage.setItem('userInfo', JSON.stringify(payload || {}));
		localStorage.setItem('id', JSON.stringify(payload.id || 0));
		localStorage.setItem('token', JSON.stringify(payload.token || ""));
		localStorage.setItem('user_type', JSON.stringify(payload.user_type || -1));
		localStorage.setItem('grade_id', JSON.stringify(payload.grade_id || -1));
		localStorage.setItem('speciality_id', JSON.stringify(payload.speciality_id || -1));
	},
	outLogin() {
		state.userInfo = {};
		state.id = 0;
		state.token = "";
		state.user_type = -1;
		state.grade_id = -1;
		state.speciality_id = -1;

		localStorage.setItem('userInfo', JSON.stringify({}));
		localStorage.setItem('id', 0);
		localStorage.setItem('token', "");
		localStorage.setItem('user_type', -1);
		localStorage.setItem('grade_id', -1);
		localStorage.setItem('speciality_id', -1);
	},
}

export default new Vuex.Store({
	state,
	mutations,

})