<template>
    <div class="home">
        <el-carousel height="480px" class="banner_bac d-none d-sm-block">
            <el-carousel-item v-for="(item,index) in bannerList" :key="index">
                <img :src="item.banner_img" class="img-fluid">
            </el-carousel-item>
        </el-carousel>

        <el-carousel height="180px" class="banner_bac d-block d-sm-none">
            <el-carousel-item v-for="(item,index) in bannerList" :key="index">
                <img :src="item.banner_img" class="img-fluid">
            </el-carousel-item>
        </el-carousel>

        <div class="container">
            <!-- <div class="row">
				<div class="col-12 index_center">
					<div>年级</div>
					<div>
						<span class="iconfont icon-hr"></span>
						Personal Center
						<span class="iconfont icon-hr"></span>
					</div>
				</div>
				<div class="col-12"></div>
				<div class="col-4 nj_list" v-for="(item,index) in gradeCourse" :key="index"
					@click="bindGrade(item.grade_id)">
					<img :src="item.course_img" class="img-fluid">
					<span>{{ item.grade_title }}</span>

				</div>

			</div> -->
            <div class="row">
                <div class="col-12 index_center  sj_index_center">
                    <div>专业课资料</div>
                    <div>
                        <span class="iconfont icon-hr"></span>
                        Personal Center
                        <span class="iconfont icon-hr"></span>
                    </div>
                </div>
                <div class="col-12"></div>
                <div class="col-4 hy_list" v-for="(item,index) in specialityCours" :key="index"
                    @click="bindSpeciality(item.speciality_id)">
                    <img :src="item.course_img" class="img-fluid">
                    <span>{{ item.speciality_title }}</span>
                    <li></li>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
    import {
        post
    } from "@/http/api";
    export default {
        name: 'navList',
        data() {
            return {
                gradeCourse: [],
                specialityCours: [],
                bannerList: [],
            };
        },
        created() {
            this.getBanner();
            this.getGradeCourse();
            this.getSpecialityCourse();
        },
        methods: {

            getBanner() {
                post("Index/getBanner").then((res) => {
                    this.bannerList = res.data.bannerList;
                    this.configs = res.data.configs;
                });
            },

            getGradeCourse() {
                post("Index/getGradeCourse").then((res) => {
                    this.gradeCourse = res.data;
                });
            },
            getSpecialityCourse() {
                post("Index/getSpecialityCourse").then((res) => {
                    this.specialityCours = res.data;
                });
            },
            bindGrade(id) {
                this.$router.push({
                    path: `/grade`,
                    query: {
                        id: id
                    }
                });
            },
            bindSpeciality(id) {
                this.$router.push({
                    path: `/courseList`,
                    query: {
                        id: id
                    }
                });
            },
        }
    }
</script>
<style src="../assets/css/index.css"></style>