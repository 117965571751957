import toast from "./ShowToast.vue"

function install(Vue) {
	const ToastConstructor = Vue.extend(toast); //使用基础 Vue 构造器，创建一个“子类 ，可以通过js语法实例化组件并挂载到对应地方
	const instance = new ToastConstructor();
	instance.$mount(document.createElement("div"));
	document.body.appendChild(instance.$el);
	Vue.prototype.$Toast = {
		info(message, duration = 1500) {
			instance.showToast = false;
			Vue.nextTick(() => {
				instance.message = message;
				instance.duration = duration;
				instance.type = "info"
				instance.showToast = true

			})
		},
		loading(message) {
			instance.showToast = false;
			Vue.nextTick(() => {
				instance.message = message;
				instance.type = "loading"
				instance.showToast = true

			})
		},
		clear() {
			instance.clear()
		},


	}
}

export default install