<template>
  <div id="bottomList">
    <div class="container-fluid">
      <div class="row bottom_bc">
            <div class="col-12 bottom_top_list">
                <a href="#">网站首页</a>
                <span class="iconfont icon-vertical_line"></span>
                <a href="#">个人中心</a>
                <span class="iconfont icon-vertical_line"></span>
                <a href="#">专业课程</a>
            </div>
            <div class="col-12 bottom_list" style="margin-top: 15px;">
                <a href="#">{{ bottomInfo.bottom_info }}</a>
            </div>
            <div class="col-12 bottom_list" style="margin-bottom: 25px;">
                <a href="#">地址：{{ bottomInfo.address }}</a>
            </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { post } from "@/http/api";
export default {
  name: 'bottomList',
  data() {
    return {
      bottomInfo:''
    };
  },
  created() {
    this.getBottomInfo();
  },
  methods: {
    getBottomInfo(){
      post("Index/getBottomInfo").then((res) => {
        this.bottomInfo = res.data;
      });
    },
  }
}
</script>
<style src="../assets/css/head.css"></style>
<style src="../assets/css/iconfont.css"></style>

  