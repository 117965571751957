<template>
	<div class="toast" v-show="showToast">
		<div class="info" v-if="type == 'info'">{{ message }}</div>
		<div class="loading" v-if="type == 'loading'">
			<div class="circle">
				<div class="line" style="--line:1"></div>
				<div class="line" style="--line:2"></div>
				<div class="line" style="--line:3"></div>
			</div>
			<div class="message">{{ message }}</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				message: "",
				duration: 3000,
				timer: null,
				type: "info",
				showToast: false,
				forbidClick: true, //加载时禁止点击
				lock: false,
			}
		},
		watch: {
			showToast(val) {
				if (val) {
					if (this.type == "info") {
						this.startTime()
					} else {
						clearTimeout(this.timer)
						this.loading()
					}
				}
			}
		},
		methods: {
			startTime() {
				clearTimeout(this.timer) //之前的未关闭，需要清除，保障全局唯一性
				this.timer = setTimeout(() => {
					this.clear()
				}, this.duration)
			},
			clear() {
				this.showToast = false
				this.lock = false;
				this.lockClick()
			},
			loading() {
				this.lock = true
				this.lockClick();
			},
			lockClick() {
				if (this.lock && this.forbidClick) {
					document.body.classList.add('unclickable');
				} else {
					document.body.classList.remove('unclickable');
				}

			}
		}
	}
</script>

<style lang="less">
	:root {
		--dw: min(calc(100vw/750), 750px/750);
	}

	.px(@ww, @att) {
		@{att}: calc(@ww * var(--dw));
	}

	.padding(@top, @left, @att) {
		@{att}-top: calc(@top * var(--dw));
		@{att}-bottom: calc(@top * var(--dw));
		@{att}-left: calc(@left * var(--dw));
		@{att}-right: calc(@left * var(--dw));
	}

	.toast {
		min-width: 380px;
		box-sizing: border-box;
		border-radius: 4px;
		border-width: 1px;
		border-style: solid;
		border-color: #EBEEF5;
		position: fixed;
		left: 50%;
		top: 20px;
		color: rgba(17, 17, 17, 0.6);
		transform: translateX(-50%);
		background-color: #edf2fc;
		transition: opacity .3s, transform .4s, top .4s;
		overflow: hidden;
		padding: 15px 15px 15px 20px;
		display: flex;
		align-items: center;

		// position: fixed;
		// left: 50%;
		// top: 25%;
		// top: 25%;
		// transition: all .5s;
		// transform: translateX(-50%) translateY(-50%);
		// color: #FFF;
		// text-align: center;
		// background: rgba(17, 17, 17, 0.6);
		// max-width: 80%;
		// z-index: 9999;
		// .px(200, min-width);
		// .padding(16, 32, padding);
		// .px(24, border-radius);
		// .px(24, font-size);
	}

	.loading {
		min-height: calc(200*var(--dw));
		display: flex;
		flex-direction: column;
		justify-content: center;

		.circle {
			.line {
				display: inline-block;
				.px(15, width);
				.px(15, height);
				.px(15, border-radius);
				background-color: #FE0033;
				animation: loadingA 0.6s linear infinite;
				animation-delay: calc(0.1s*var(--line));
			}

		}

		.message {
			margin-top: calc(32*var(--dw));
			font-weight: 400;
			color: white;
		}

		@keyframes loadingA {
			0% {
				transform: translate(0, 0);
			}

			50% {
				transform: translate(0, calc(15*var(--dw)));
			}

			100% {
				transform: translate(0, 0);
			}
		}

	}

	.unclickable {
		overflow: hidden;

		* {
			pointer-events: none;
		}

	}
</style>