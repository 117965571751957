import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
Vue.use(VueRouter)
const routes = [{
        path: '/',
        name: 'home',
        meta: {
            isAuth: true, //判断是否进行校验
            title: '生态景观营造专业群资源库程序',
        },
        component: HomeView
    },
    {
        path: '/searchList',
        name: 'searchList',
        meta: {
            isAuth: true, //判断是否进行校验
            title: '搜索'
        },
        component: () => import('../views/searchView.vue')
    },
    {
        path: '/grade',
        name: 'grade',
        meta: {
            isAuth: true, //判断是否进行校验
            title: '年级课程列表'
        },
        component: () => import('../views/gradeView.vue')
    },

    {
        path: '/speciality',
        name: 'speciality',
        meta: {
            isAuth: true, //判断是否进行校验
            title: '课程列表'
        },
        component: () => import('../views/specialityView.vue')
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            isAuth: false, //判断是否进行校验
            title: '登录'
        },
        component: () => import('../views/LoginView.vue')
    },
    {
        path: '/courseList',
        name: 'courseList',
        meta: {
            isAuth: true, //判断是否进行校验
            title: '专业课程'
        },
        component: () => import('../views/courseListView.vue')
    },
    {
        path: '/courseInfo',
        name: 'courseInfo',
        meta: {
            isAuth: true, //判断是否进行校验
            title: '课程详情'
        },
        component: () => import('../views/courseInfoView.vue')
    },
    {
        path: '/userInfo',
        name: 'userView',
        meta: {
            isAuth: true, //判断是否进行校验
            title: '个人中心'
        },
        component: () => import('../views/userView.vue')
    }

]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

//全局前置路由守卫-- 初始化的时候被调用,每次路由切换之前被调用
router.beforeEach((to, from, next) => {
    var userInfo = localStorage.getItem("userInfo");
    var id = localStorage.getItem("id");
    var token = localStorage.getItem("token");

    console.log("path=" + to.path);
    console.log("userInfo=" + userInfo);
    if (to.path == '/login') {
        if (id && id > 0 && token) {
            console.log("已登录");
            next("/");
        } else {
            next();
        }
    } else {
        //判断是否进行校验
        if (to.meta.isAuth) {
            if (id && id > 0 && token) {
                console.log("已登录");
                next();
            } else {
                Vue.prototype.$Toast.info("请先登录");
                console.log("未登录");
                next("/login");
            }
        } else {
            next();
        }
    }

});
//暴露路由,便于在main.js当中引入路由

export default router