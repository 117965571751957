import { render, staticRenderFns } from "./bottom.vue?vue&type=template&id=adacebce"
import script from "./bottom.vue?vue&type=script&lang=js"
export * from "./bottom.vue?vue&type=script&lang=js"
import style0 from "../assets/css/head.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "../assets/css/iconfont.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports